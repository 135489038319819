/* global $ Craft */

import 'lazysizes';

class App
{
    bootstrap;

    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initFontAwesome();
        this.initHighlight();
        this.initCopyCode();
        this.initMainMenu();
        this.fixContentHeights();
        $(window).resize(() => {
            this.fixContentHeights();
        });
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initHeader();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    disableLogger()
    {
        if (Craft.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-solid, .fa-brands, .fa-regular').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initHeader()
    {
        if (!$('header').hasClass('has-border')) {
            $(document).scroll(() => {
                if ($(document).scrollTop() > 50) {
                    $('header').addClass('bordered');
                } else {
                    $('header').removeClass('bordered');
                }
            });
        }
    }

    initHighlight()
    {
        if ($('pre').length) {
            import(/* webpackChunkName: "highlight" */ './components/highlight').then((hljs) => {
                document.querySelectorAll('pre').forEach((el) => {
                    hljs.default.highlightElement(el);
                });
            });
        }
    }

    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(form => {
                $.each($('form'), (i, elem) => {
                    new form.default(elem.id);
                });
            });
        }
        if ($('select.js-select2').length) {
            import(/* webpackChunkName: "select2" */ './components/select2').then(() => {
                $.each($('select.js-select2'), (i, select) => {
                    let options = {};
                    if ($(select).data('options')) {
                        options = $(select).data('options');
                    }
                    $(select).select2(options);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"),(i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    initToasts(elems)
    {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initCopyCode()
    {
        if (!$('code, pre').length) {
            return;
        }
        $.each($('code, pre'), function (i, elem) {
            $(elem).attr('data-bs-toggle', 'tooltip');
            $(elem).attr('data-bs-placement', 'top');
            $(elem).attr('title', 'Copy');
        });
        this.getBootstrap().then((bootstrap) => {
            $('code, pre').click(function () {
                var $temp = $("<textarea>").css({
                    position: 'fixed',
                    top: 0,
                    left: 0
                });
                $("body").append($temp);
                $temp.val($(this).text()).select();
                document.execCommand("copy");
                $temp.remove();
                bootstrap.Toast.getInstance($('#copied-toast')[0]).show();
            });
        });
    }

    initTooltips(elems)
    {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    fixContentHeights()
    {
        let min = $(window).height() - $('#header').outerHeight() - $('#footer').outerHeight();
        $('#content').css('min-height', min + 'px');
    }

    initMainMenu()
    {
        $('.navbar-toggler').click(() => {
            if (this.menuMoving) {
                return;
            }
            this.menuMoving = true;
            if ($('.navbar-toggler').hasClass('open')) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        });
    }

    openMenu()
    {
        $('.navbar-toggler').addClass('open');
        $('#main-menu').fadeIn('fast', () => {
            this.menuMoving = false;
        });
    }

    closeMenu()
    {
        $('.navbar-toggler').removeClass('open');
        $('#main-menu').fadeOut('fast', () => {
            this.menuMoving = false;
        });
    }
}

export default App;